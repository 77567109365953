exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lac-lac-contacts-js": () => import("./../../../src/pages/lac/lac_contacts.js" /* webpackChunkName: "component---src-pages-lac-lac-contacts-js" */),
  "component---src-pages-lac-lac-services-js": () => import("./../../../src/pages/lac/lac_services.js" /* webpackChunkName: "component---src-pages-lac-lac-services-js" */),
  "component---src-pages-lac-lac-team-js": () => import("./../../../src/pages/lac/lac_team.js" /* webpackChunkName: "component---src-pages-lac-lac-team-js" */),
  "component---src-pages-miera-miera-about-js": () => import("./../../../src/pages/miera/miera_about.js" /* webpackChunkName: "component---src-pages-miera-miera-about-js" */),
  "component---src-pages-miera-miera-blog-js": () => import("./../../../src/pages/miera/miera_blog.js" /* webpackChunkName: "component---src-pages-miera-miera-blog-js" */),
  "component---src-pages-miera-miera-contacts-js": () => import("./../../../src/pages/miera/miera_contacts.js" /* webpackChunkName: "component---src-pages-miera-miera-contacts-js" */),
  "component---src-pages-miera-miera-gallery-js": () => import("./../../../src/pages/miera/miera_gallery.js" /* webpackChunkName: "component---src-pages-miera-miera-gallery-js" */),
  "component---src-pages-miera-miera-studyplans-js": () => import("./../../../src/pages/miera/miera_studyplans.js" /* webpackChunkName: "component---src-pages-miera-miera-studyplans-js" */),
  "component---src-pages-miera-miera-team-js": () => import("./../../../src/pages/miera/miera_team.js" /* webpackChunkName: "component---src-pages-miera-miera-team-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

